import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Container, Head, Post } from 'Components/common';
import './highlight.scss';

export default function Template({ data }) {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Head
        type="NewsArticle"
        headline={post.frontmatter.title}
        articleBody={post.html}
        datePublished={post.frontmatter.date}
        dateModified={
          post.frontmatter.edited
            ? post.frontmatter.edited
            : post.frontmatter.date
        }
        cover={post.frontmatter.thumbnail.childImageSharp.fluid.originalImg}
        location={post.frontmatter.path}
      >
        {post.frontmatter.title}
      </Head>
      <Post {...post} />
    </Layout>
  );
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      timeToRead
      frontmatter {
        date(formatString: "MMM DD, YYYY")
        edited(formatString: "MMM DD, YYYY")
        path
        title
        author
        author_avatar {
          childImageSharp {
            fluid(maxWidth: 700) {
              originalImg
            }
          }
        }
        next
        id
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 700) {
              originalImg
            }
          }
        }
      }
    }
  }
`;
